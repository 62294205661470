<template>
    <div class="google-map-area section" id="support">
<!--        <GmapMap-->
<!--            :center="{lat:40.4168, lng:-3.7038}"-->
<!--            :zoom="12"-->
<!--            map-type-id="terrain"-->
<!--        >-->
<!--        </GmapMap>-->
      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d194347.38441087783!2d-3.8196193438883084!3d40.438131079532496!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd422997800a3c81%3A0xc436dec1618c2269!2sMadrid%2C%20Spain!5e0!3m2!1sen!2seg!4v1610127986163!5m2!1sen!2seg" width="100%" height="300" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>

    </div>
</template>

<script>
    import Vue from 'vue';
    import * as VueGoogleMaps from 'vue2-google-maps'
    Vue.use(VueGoogleMaps, {
        load: {
            key: 'AIzaSyB2D8wrWMY3XZnuHO6C31uq90JiuaFzGws',
        },
    
    })
    export default {
        name: 'GoogleMap',
    }
</script>
